<script setup>
import configs from '@/configs/config';
import axios from 'axios';
import { ref , onMounted } from 'vue'

const resourse = ref('')
const Category = ref('/resource/')
const loading = ref(true)

onMounted(() => {
    getRecourses()
 })

async function getRecourses() {
    try {
    loading.value = true;
    const response = await axios.get(`${configs.BackJSON}/ResourceDota`)
    resourse.value = response.data
    } catch (err) {
    console.log(err)
    } finally {
        setTimeout(() => {
            loading.value = false;
        }, 500);
    }
}
</script>
<template>
 <div class="category-res">
    <h4 
    style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
    v-if="loading">
        Загрузка...
    </h4>
    <div 
    v-else
    class="box-res" v-for="res in resourse" :key="res.id">
      <router-link :to="Category + res.Router">
        <div class="header-res">
            <img 
                class="img-res"
                :src="res.Resimg">
        </div>
        <div class="bottom-res">
            <span>{{ res.WhenAdded }} - {{ res.TitleAuthor }}</span>
            <h3>{{ res.Title }}</h3>
            <h4>{{ res.TitleText }}</h4>
        </div>
        </router-link>
 </div>
 </div>
</template>
<style scoped>
.category-res{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 25px 0 25px 0;
}
.header-res{
    width: 100%;
    height: auto;
}
.item-one{
    width: 30%;
}
.box-res{
    width: 30%;
    margin-bottom: 12px;
    margin-top: 5px;
}
.box-res img{
    width: 100%;
    min-height: 150px;
    max-height: 250px;
    border-radius: 15px 15px 0 0;
}
.bottom-res{
    height: 165px;
    padding: 6px 6px 10px;
    background: #f8fbfc;
    border: 1px solid #c9daeb;
    border-radius: 0 0 15px 15px;
}
h4{
    font-size: small;
    line-height: 0.9;
}
h3{
    line-height: 0.9;
}
@media(max-width:420px){
    .box-res{
        width: 95%;
        margin-bottom: 20px;
    }
    .box-res img{
        width: 99%;
        height: auto;
        border-radius: 3px;
    }
    .bottom-res{
        height: auto;
        padding:0;
    }
}
</style>