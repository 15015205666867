<template>
  <div class="container-dota log-form" v-if="!userInfo">
    <h3>Регистрация</h3>
    <form @submit.prevent="createRegister">
        <img src="../assets/img/logo-fakafero.png"
          width="100"
          height="100"><br>
         <label>Nickname</label><br>
         <input
             v-model="userName"
             type="text"
         ><br>
         <label>Почта</label><br>
         <input
             v-model="email"
             type="email"
         ><br>
         <label>Пароль</label><br>
          <input 
            type="password"
            v-model="Password"
            ><br>
          <button type="submit" :disabled="Password.length < 6 || userName.length < 3 || email.length < 5" class="btn btn-primary mt-2">Зарегистрироваться</button>
     </form>
     <div class="public-oferta">
      <input
      type="checkbox"
      id="subscribeNews"
      name="subscribe"
      value="newsletter"
      checked />
    <label for="subscribeNews">
       Нажимая кнопку "Зарегистрироваться", вы подтверждаете свое согласие с условиями <router-link to="/public-oferta">Публичной оферты</router-link> и разрешаете обработку персональных данных.
    </label>
     </div>
  </div>
  <div v-else>
  <notFound/>
  </div>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import notFound from '@/pages/NotFound.vue'
import configs from '../configs/config'

        const userName = ref("")
        const email = ref("")
        const Password = ref("")
        const router = useRouter();
        const userInfo = window.localStorage.getItem('yf_395493')
        const userCInfo = JSON.parse(userInfo)

        const createRegister = async () => {
            await axios.post(`${configs.apiURL}/api/auth/local/register`, 
            {
                "username": userName.value,
                "email": email.value,
                "password": Password.value
            })
            .then(() => {
              router.push({path:'/login'})
            })
            .catch((error) => {
              if(error) {
                alert('Выберите другое ник, данный ник уже зарегистрирован :D')
              }  
            });
}
</script>

<style>
.public-oferta{
  display: flex;
}
.log-form{
  width: 20%;
  margin: 55px auto;
  background: #fff;
  padding: 15px;
  border-radius: 4%;
}

.log-form label{
  margin-top: 25px;
  font-family: 'vue-w25';
  font-family: small;
}

label{
    font-family: 'vue-w22';
    color: #333;
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=email], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=password], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
input[type=date], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
@media(max-width: 499px){
  .log-form{
    width: 98%;
    margin: 125px auto;
    border-radius: 4%;
  }
}
</style>