<template>
<div class="container-xxl">
<h2>Публичная оферта</h2>
<p>Настоящий документ является официальным предложением (публичной офертой) "Мапхак для Дота 1", далее именуемого "Исполнитель", и содержит все существенные условия предоставления услуг через сайт Hackcup.ru. В соответствии со статьей 435 Гражданского кодекса Российской Федерации, данный документ адресован неопределенному кругу лиц и является публичной офертой.</p>

<h3>1. Предмет оферты</h3>
<p>1.1. Исполнитель обязуется предоставить пользователю (далее — "Заказчик") услуги, связанные с предоставлением доступа к программному обеспечению "Мапхак для Дота 1" (например, использование мaphack для игры Dota 1), в порядке и на условиях, предусмотренных настоящей офертой.<br>
1.2. Регистрируясь на сайте Hackcup.ru или оплачивая услуги, Заказчик подтверждает свое полное и безоговорочное согласие с условиями настоящей публичной оферты, что является акцептом оферты.
</p>

<h3>2. Права и обязанности сторон<br>
    2.1. Исполнитель обязуется:</h3>

<p>Предоставить доступ к услугам в соответствии с описанием, указанным на сайте Hackcup.ru.
Обеспечить конфиденциальность данных Заказчика в соответствии с Политикой конфиденциальности.
Иметь право собирать и обрабатывать IP-адрес Заказчика в целях обеспечения безопасности работы сайта Hackcup.ru и защиты от несанкционированного доступа, в соответствии с действующим законодательством о персональных данных.<br>
 2.2. Заказчик обязуется:
Оплатить услуги в порядке и сроки, указанные на сайте.
Соблюдать правила использования сайта и программного обеспечения.
Предоставить достоверные данные при регистрации, соглашаясь на их обработку в соответствии с настоящей офертой.</p>
<h3>3. Стоимость услуг и порядок оплаты</h3>
<p>3.1. Стоимость услуг указана на сайте Hackcup.ru в разделах "Цены" и "Услуги".<br>
3.2. Оплата производится Заказчиком посредством банковской карты или электронных платежных систем.<br>
3.3. Моментом оплаты считается поступление денежных средств на счет Исполнителя.</p>

<h3>4. Ответственность сторон</h3>
<p>4.1. Исполнитель не несет ответственности за сбои в работе сайта или программного обеспечения, вызванные действиями третьих лиц, а также за убытки, возникшие не по вине Исполнителя.<br>
    4.2. Заказчик несет ответственность за достоверность предоставленных данных и соблюдение условий настоящей оферты.</p>

<h3>5. Порядок разрешения споров</h3>
<p>
5.1. Все споры, возникающие в связи с настоящей офертой, решаются путем переговоров. <br>
5.2. В случае невозможности урегулирования спора в досудебном порядке он подлежит рассмотрению в суде по месту нахождения Исполнителя.</p>
<p>
    6. Срок действия оферты
6.1. Настоящая оферта вступает в силу с момента ее размещения на сайте Hackcup.ru и действует до ее отзыва Исполнителем.
</p>

<p>7. Реквизиты Исполнителя (опционально)
    "Мапхак для Дота 1"</p>
</div> 

</template>
<style scoped>
h2{
    font-family: 'vue-w25';
}
</style>
