<template>
    <div class="container-fluid footer-style">
        <div class="row d-flex">
            <div class="col">
                <ul>
                    <a href="https://t.me/hackcup" target="_blank"><li>Мы в Телеграмм</li></a>
                    <a href="https://vk.com/iccupstealhmx" target="_blank"><li>Мы в Вк</li></a>
                    <li>iCCup Stealh Maphack (Elite)</li>
                </ul>
            </div>
            <div class="col">
                <li>Условия использования 18+</li>
                <router-link to="/public-oferta"><li class="mt-3">Публичная оферта</li></router-link>
            </div>
        </div>
    </div>
</template>

<style>
.footer-style {
    margin-top: 25px;
    padding-top: 25px;
    height: 165px;
    border-top: 1px solid linear-gradient(to bottom,rgba(19,23,31,1) 0%,rgba(3,3,3,1) 65%,rgba(3,2,2,1) 100%);;
    background: linear-gradient(to bottom,rgba(19,23,31,1) 0%,rgba(3,3,3,1) 65%,rgba(3,2,2,1) 100%);
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
    border-radius: 1px;
}

li{
    font-family: 'vue-w25';
    font-size: 15px;
    color: #65758c;
}
</style>