import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/pages/HomeView.vue";
import WhatCanHack from "@/pages/WhatCanHack.vue"
import DotaNews from "@/pages/DotaNews.vue"
import DotaPrices from "@/pages/DotaPrices.vue"
import DotaRegister from "@/pages/DotaRegister.vue"
import DotaNotFound from "@/pages/NotFound.vue"
import CreatePost from "@/pages/CreatePost.vue"
import DotaLogin from "@/pages/DotaLogin.vue"
import Account from "@/components/Account.vue"
import Instruction from "@/components/Instruction.vue"
import Resource from '@/News/ResCategory.vue'
import Wallets from "@/components/wallets.vue"
import ActiveKey from "@/components/Active_key.vue"
import Lastkeys from '@/components/LastKey.vue'
import Feedback from '@/components/Feedback.vue'
import GetLogs from '@/components/_Logs.vue'
import AdmGetLogs from '@/components/_AdminLogs.vue'
import AdminLogsItem from '@/components/_AdminLogs_Item.vue'
import AdminKeysItem from '@/components/_AdminKeys_item.vue'
import PrizeDota from '@/pages/PrizeUsers.vue'
import ADMPRIZE from '@/components/_AdminPrize_item.vue'
import ADMRESOURCE from '@/News/_Admin_item.vue'
import ItemRes from '../News/_Res_item.vue'
import PublicOferta from '@/components/Public_oferta.vue'
import Cabinet_Key from '@/pages/_Cabinet_Keybuy.vue'
import CabinetSklad from '@/pages/_Cabinet_Sklad.vue'
import CabinetInstruction from '@/pages/_Cabinet_instruction.vue'
import CabinetRuletka from '../pages/_Cabinet_Ruletka.vue'

const routes = [
  {
    path: "/",
    component: HomeView,
  },
  {
    path: "/abouthack",
    component: WhatCanHack,
  },
  {
    path: "/news",
    component: DotaNews,
  },
  {
    path: "/prices",
    component: DotaPrices,
  },
  {
    path: "/register",
    component: DotaRegister,
  },
  {
    path: "/login",
    component: DotaLogin,
  },
  {
    path: "/poster",
    component: CreatePost,
  },
  {
    path: "/account",
    component: Account,
  },
  {
    path: "/instructionSecretInformation",
    component: Instruction,
  },
  {
    path: "/resource",
    component: Resource,
  },
  {
    path: "/wallets",
    component: Wallets,
  },
  {
    path: "/activeKey",
    component: ActiveKey,
  },
  {
    path: "/lastkeys",
    component: Lastkeys,
  },
  {
    path: "/feedback",
    component: Feedback,
  },
  {
    path: "/getlogs",
    component: GetLogs,
  },
  {
    path: "/admingetlogs",
    component: AdmGetLogs,
  },
  {
    path: "/prize",
    component: PrizeDota,
  },
  {
    path: "/public-oferta",
    component: PublicOferta,
  },
  {
    path: "/cabinet/key",
    component: Cabinet_Key,
  },
  {
    path: "/cabinet/ruletka",
    component: CabinetRuletka,
  },
  {
    path: "/cabinet/sklad",
    component: CabinetSklad,
  },
  {
    path: "/cabinet/instruksiya",
    component: CabinetInstruction,
  },
  {
    path: "/resource/:itemAlias",
    component: ItemRes,
  },
  {
    path: "/admin/addprize",
    component: ADMPRIZE,
  },
  {
    path: "/admin/addres",
    component: ADMRESOURCE,
  },
  {
    path: "/admin/:itemAlias",
    component: AdminLogsItem,
  },
  {
    path: "/adminkeys/:itemAlias",
    component: AdminKeysItem,
  },
  {
    path: '/:CatchAll(.*)',
    component: DotaNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
