<template>
  <div>
    <div v-if="loading"><h2>Скачиваем данные</h2></div>
    <div v-else-if="error">{{ error }}</div>
    <div class="container-item" v-else>
      <div v-if="databaseObjects.length === 0">
        <h3>Ваш склад пуст</h3>
      </div>
      <div v-else v-for="item in databaseObjects" :key="item.id" class="item">
        <img src="../assets/img/kart-key.png" width="70">
        <h4>ID - Мапхака<br> {{ item.HWID }}</h4>
        <div class="dates">
          <p style="color: red;">Конец: {{ item.startDate.toDateString() }}</p>
          <p style="color: green;">Начало: {{ item.targetDate.toDateString() }}</p>
        </div>
        <div class="caps">
         <p>{{ item.cupsfromsite }}</p>
         <img src="../assets/img/keysAmount.png" width="35">
        </div>
        <textarea
          readonly
          v-model="item.KEY"
          rows="9" 
          cols="52"/>
        <p v-if="item.daysOverdue > 0" class="overdue">Код закончился еще - {{ item.daysOverdue }} дней назад.</p>
        <p v-else>Код закончится через: {{ item.daysRemaining }} - дней</p>
        <div class="progress-anim progress-bar" :class="{ 'overdue-bar': item.daysOverdue > 0 }">
          <div 
            :style="{ width: item.progressPercentage + '%' }" 
            :class="{ 'progress-fill': item.daysOverdue <= 0, 'overdue-fill': item.daysOverdue > 0 }"
          ><p style="font-size: smaller">{{ item.progressPercentage }}%</p></div>
        </div>
     </div>
    </div>
    <div class="pagination">
        <button 
        class="btn btn-primary"
        @click="prevPage" :disabled="currentPage === 1">Предыдущая</button>
        <span>Страница {{ currentPage }}</span>
        <button
        class="btn btn-primary" 
        @click="nextPage" :disabled="databaseObjects.length < itemsPerPage">Следующая</button>
      </div>
  </div>
</template>

  
<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
  const userKeys = window.localStorage.getItem('identification')
  const userKeysA = userKeys
  
  // Constants
  const TOTAL_DAYS = 31; // Примерное значение, замените на своё
    const MS_PER_DAY = 24 * 60 * 60 * 1000; // Миллисекунды в дне
    const itemsPerPage = 3; // Лимит объектов на страницу

    // Реактивные переменные
    const databaseObjects = ref([]); // Массив объектов
    const currentPage = ref(1); // Текущая страница
    const loading = ref(false); // Состояние загрузки
    const error = ref(null); // Ошибка

    // Функция для получения данных с пагинацией
    const fetchData = async (page) => {
      loading.value = true;
      error.value = null;
      databaseObjects.value = []; // Очищаем перед новым запросом

      try {
        const response = await fetch(`https://g2dev-res.ru/Dota?IDFSITE=${userKeysA}&_sort=Time&_order=desc&_page=${page}&_limit=${itemsPerPage}`);
        if (!response.ok) throw new Error('Ошибка сети');
        const data = await response.json();

        // Обработка данных
        data.forEach((item) => {
          item.startDate = new Date(item.Time); // Предполагается поле Time
          item.targetDate = new Date(item.startDate.getTime() - TOTAL_DAYS * MS_PER_DAY);
          item.daysPassed = 0;
          item.daysRemaining = TOTAL_DAYS;
          item.daysOverdue = 0;
          item.progressPercentage = 0;
          item.cupsfromsite = item.CAPS; // Предполагается поле CAPS
          databaseObjects.value.push(item);
        });
      } catch (err) {
        error.value = 'Не получилось получить данные с сервера, увы!';
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    // Переход на предыдущую страницу
    const prevPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
        fetchData(currentPage.value);
        updateProgress() // Initial calculation
        intervalId = setInterval(updateProgress, 1000) // Update every minute
      }
    };

    // Переход на следующую страницу
    const nextPage = () => {
      currentPage.value++;
      fetchData(currentPage.value);
      updateProgress() // Initial calculation
      intervalId = setInterval(updateProgress, 1000) // Update every minute
    };
  
  // Function to calculate progress for all items
  const updateProgress = () => {
    const now = new Date()
    
    databaseObjects.value.forEach(item => {
      const timeDiff = item.startDate - now
      const daysUntilStart = Math.ceil(timeDiff / MS_PER_DAY)
      item.daysPassed = TOTAL_DAYS - daysUntilStart
      
      if (item.daysPassed < 0) { // Before target date
        item.daysPassed = 0
        item.daysRemaining = TOTAL_DAYS
        item.daysOverdue = 0
        item.progressPercentage = 0
      } else if (item.daysPassed > TOTAL_DAYS) { // After start date (overdue)
        item.daysOverdue = item.daysPassed - TOTAL_DAYS
        item.daysRemaining = 0
        item.progressPercentage = 100
      } else { // Between target and start date
        item.daysOverdue = 0
        item.daysRemaining = TOTAL_DAYS - item.daysPassed
        item.progressPercentage = Math.round((item.daysPassed / TOTAL_DAYS) * 100)
      }
    })
  }
  
  let intervalId = null
  
  // Lifecycle hooks
  onMounted(async () => {
    await fetchData() // Fetch initial data
    updateProgress() // Initial calculation
    intervalId = setInterval(updateProgress, 60000) // Update every minute
  })
  
  onUnmounted(() => {
    if (intervalId) {
      clearInterval(intervalId)
    }
  })
  </script>
  
<style scoped>
.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  list-style: none;
  position: fixed;
  bottom: 3%;
  left: 1%;
}
.pagination span{
  margin: 8px 9px 0 9px
}
h2{
    font-family: 'vue-w25';
    font-size: x-large;
    margin-bottom: 35px;
    margin-top: 35px;
}
.caps{
    display: flex;
    justify-content: center;
    align-items: center;
}
.caps p{
    margin: 0;
}
.dates{
    display: flex;
    justify-content: space-evenly;
}
.dates p{
    font-size: smaller;
}
h4{
    font-family: 'vue-w25';
    font-size: smaller;
    overflow: hidden;
}
.container-item{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}
.item {
    border: 1px solid #c9daeb;
    background-color: #f8fbfc;
    padding: 5px;
    margin-bottom: 15px;
    border-radius: 12px;
    width: 30%;
}
  
.progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
}
  
.progress-fill {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease-in-out;
    animation: progressAnimationStrike 1s;
}
.progress-anim{
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.25) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
    animation: progressAnimationStrike 1s;
}
  
.overdue-bar {
    background-color: #ffebee;
}
  
.overdue-fill {
    height: 25px;
    max-height: 40px;
    background-color: #f44336;
    transition: width 0.3s ease-in-out;
}
  
.overdue {
    color: #f44336;
    font-weight: bold;
}
textarea{
    font-size: 6px; 
    text-align: center; 
    font-family: 'vue-w25'; 
    border-radius: 5px;
    width: 90%;
    height: auto;
}
@media(max-width: 450px){
    textarea{
        font-size: 6.5px;
        width: 90%;
        height: 15vh;
    }
    .item{
        width: 98%;
        padding: 0;
    }
    h4{
        font-size: 6px;
        overflow: hidden;
    }
    .dates{
        flex-wrap: wrap;
    }
    .pagination{
      position: unset;
    }
    .container-item{
      flex-wrap: wrap;
    }
}
@keyframes progressAnimationStrike {
     from { width: 0 }
     to   { width: 100% }
}
</style>