<script setup>
import configs from '../configs/config'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import Footer_main from '@/components/Footer_main.vue'

const Resource = ref('')

const route = useRoute()
const Router = route.params.itemAlias

onMounted(() => {
    getDetailsRes()
 })

const getDetailsRes = async () =>{
    await axios.get(`${configs.BackJSON}/ResourceDota?Router=${Router}`)
    .then(response => {
        Resource.value = response.data
        console.log(response.data)
    })
    .catch(err => {
        console.log(err)
    })
}


</script>
<template>
    <div class="cointainer-xxl" v-for="res in Resource" :key="res.id">
      <div class="res-info mb-5">
        <div class="top">
            <iframe
            v-if="res.Youtube.length > 10"
            :src="res.Youtube" 
            title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
            </iframe>

            <img 
            v-else 
            :src="res.Resimg">
            <a 
            :href="res.Download" 
            target="_blank">
                <button class="btn btn-secondary btn-lg mt-3">
                Скачать Бесплатно
                </button>
            </a> 
        </div>
        <div class="right">
            <h1>{{ res.Title }}</h1>
            <span>{{ res.TitleAuthor }} - {{ res.WhenAdded }}</span>
            <h3>{{ res.TitleText }}</h3>
            <h4>{{ res.TitleText2 }}</h4>
            <h4>{{ res.Install }}</h4>
        </div>
        </div>
        <Footer_main/>
      </div>
</template>
<style scoped>
iframe{
    width: 95%;
    height: 320px;
    border-radius: 9px;
}
.res-info {
    width: 100%;
    display: flex;
    margin-top: 25px;
    height: 60vh;
}
.top{
    width: 40%;
}
.top button{
    font-family: 'vue-w25';
}
.right{
    width: 60%;
}
.top img{
    width: 90%;
    height: auto;
    margin-top: 15px;
    border-radius: 5px;
}
h1{
    font-family: 'vue-w25';
}
h3{
    font-family: 'vue-w25';
    font-size: x-large;
    margin-top: 5px;
}
h4{
    font-family: 'vue-w25';
    font-size: large;
    margin-top: 8px;
}
@media(max-width:420px){
    .res-info{
        flex-wrap: wrap;
        height: auto;
    }
    .top{
        width: 99%;
    }
    .right{
        width: 99%;
    }
    h1{
        margin-top: 10px;
    }
    h3{
        font-size: smaller;
    }
    h4{
        font-size: small;
    }
    
}
</style>