<script setup>
import Ruletka from './_Component_Ruletka.vue'
const userKeys = window.localStorage.getItem('identification')
const userKeysA = userKeys
const userJwt = window.localStorage.getItem('sc_359649')
const userJwtG = userJwt


</script>

<template>
<div class="container-cabinet" v-if="userKeys">
    <div class="left-side">
        <ul>
            <router-link to="/cabinet/instruksiya"><h3>Личный кабинет</h3></router-link><br>
            <p>Версия: 0.1</p>
            <img src="../assets/img/Tutorials.png">
            <router-link to="/cabinet/instruksiya"><li>Инструкция</li></router-link>
           <router-link  to="/cabinet/key"><li>Магазин</li></router-link>
           <router-link  to="/cabinet/ruletka"><li>Рулетка</li></router-link>
           <router-link to="/cabinet/sklad"><li>Склад Ключей</li></router-link>
           <a href="https://mega.nz/file/OW5hmLQC#xKZ_kfjPjCdXXnnwyYIs4IRJdN3XHNNp1sxC1OC_rac" target="_blank"><li>Скачать</li></a>
        </ul>
    </div>
    <div class="right-side">
        <Ruletka/>
    </div>
</div>
<div v-else><h3>Ничего не нашли</h3></div>
</template>
<style scoped>
.router-link-exact-active {
    background-color: #282d35;
    color: #fff;
}
.container-cabinet{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
}
.left-side{
    width: 25%;
    height: 390px;
    border: 1px solid #c9daeb;
    border-radius: 8px;
    background-color: #f8fbfc;
    box-shadow: 0px 6px 30px 0px rgba(6, 127, 221, 0.2);
    padding: 15px;
}
.left-side ul li{
    font-family: 'vue-w25';
    font-size: 1.35rem;
    margin-bottom: 8px;
}
.left-side img{
    width: 78px;
    height: auto;
}
.right-side{
    width: 70%;
}
.links-leftarea{
    width: 100%;
}
ul li{
    text-decoration: none;
}
ol, ul{
    padding-left: 0;
}

@media(max-width: 450px){
    .container-cabinet{
        flex-wrap: wrap;
    }
    .left-side{
        width: 99%;
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .right-side{
        width: 99%;
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
</style>