<template>
  <div class="container-dota">
    <div class="container-xxl">
      <h3>Так Чем Лучше наш Мапхак?</h3>
    <div class="row d-flex">
      <div class="col-md">
        <img 
        class="img-fluid rounded"
        src="../assets/gifs/aaaaaaa.gif" 
        >
        <h5>Возможности</h5>
          <li>Главная карта
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Мини карта
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Инвизы (везде)
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Смок (везде)
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Серый цвет ХП в тумане
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Клики в туман
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
      </div>
      <div class="col-md">
        <img 
        class="img-fluid rounded"
        src="../assets/gifs/aaaaaaa.gif" 
        >
        <h5>Возможности</h5>
          <li>Скилы + кд
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Передача голды/древесины
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Auto-follow (Proacc)
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Camera zoom
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Детектор(Клоуна)
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
          <li>Мигания (от Противников)
            <img 
            width="30"
            src="../assets/img/png_gal0chka.png" 
            >
          </li>
      </div>
      <div class="col-md">
        <img 
        class="img-fluid rounded"
        src="../assets/gifs/Terrorblade.gif" 
        >
        <h5>Защита</h5>
          <p>Что по Защите? Safe-click, то есть когда вы трогаете в тумане кого-то наш мапхак не палиться!
            Что по Дропу? У нас мапхак со своим лаунчером поэтому у нас проблема с работаспособностью нет!
          </p>
          <router-link to="/prices">
                    <div class="buyknopka2">
                    <span class="icon_maphack2"></span>
                    <span class="buyknopka_title2">Цены</span>
                    <span class="buyknopka_portred2"></span>
                    </div>
            </router-link>
      </div>
    </div>
    <Demonstration/>
    </div>
  </div>
</template>
<script>
import Demonstration from '../components/Demonstration.vue'
export default{
  components:{Demonstration}
}
</script>

<style scoped>
p{
  font-family: 'vue-w25';
  font-weight: 400;
  font-size: 19px;
  padding: 2px;
}
h5{
  font-family: 'vue-w25';
  font-size: x-large;
  margin-top: 10px;
}
li{
  font-family: 'vue-w25';
}
@media(max-width: 499px){
  li{
    font-size: small;
  }
}
</style>