<script setup>
import axios from 'axios';
import { ref } from 'vue';
import configs from '@/configs/config';

//Добавить Ресурсы
const link = ref('')
const Title = ref('')
const TitleAuthor = ref('G2dev')
const WhenAdded = ref('')
const TitleText = ref('')
const Resimg = ref('')
const TitleText2 = ref('')
const Install = ref('')
const Download = ref('')
const Youtube = ref('')

const onSubmit = async () => {
    await axios.post(`${configs.BackJSON}/resourcedota`, {
        Router: link.value,
        Title: Title.value,
        TitleAuthor: TitleAuthor.value,
        WhenAdded: WhenAdded.value,
        TitleText: TitleText.value,
        Resimg: Resimg.value,
        TitleText2: TitleText2.value,
        Install: Install.value,
        Download: Download.value,
        Youtube: Youtube.value
        
    })
    .then(() => {
        alert('Готово')
        location.reload()
    })
    .catch(() => {
        alert('Что то не так.')
    })
}

const Prize = ref('')
const getPrizeUsers = async () => {
    await axios.get(`${configs.BackJSON}/resourcedota`)
    .then(res => {
        Prize.value = res.data
    })
}

const DeleteUser = async (user) => {
    if(confirm('Удалить Ресурса?')){
        await axios.delete(`${configs.BackJSON}/resourcedota/${user.id}`)
    .then(() => {
        alert('Готово')
        location.reload()
    })
    .catch(() => {
        alert('Упс, Что то не так')
    })
    }

}

</script>
<template>
<div class="content">
    <form @submit.prevent="onSubmit">
        <div class="container-form">
            <p>Имя</p>
            <input 
            v-model="link" 
            placeholder="Ссылка"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Название</p>
            <input 
            v-model="Title" 
            placeholder="Title"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Когда куплено?</p>
            <input 
            v-model="WhenAdded" 
            placeholder="15 март 2025"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Текст первый?</p><br>
            <input 
            v-model="TitleText" 
            placeholder="Mini title"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Сссылка к рисунку?</p>
            <input 
            v-model="Resimg" 
            placeholder="file.png"
            class="form-control"
            type="text">
        </div>
        <div class="container-form">
            <p>Текст 2?</p><br>
            <textarea
                v-model="TitleText2"
                rows="10" 
                cols="50"/><br>
        </div>
        <div class="container-form">
            <p>Инструкция установка</p><br>
            <textarea
                v-model="Install"
                rows="10" 
                cols="50"/>
        </div>
        <div class="container-form">
            <p>Сссылка к Download?</p>
            <input 
            v-model="Download" 
            placeholder="Скачать"
            class="form-control"
            type="text">
         </div>
        <div class="container-form">
            <p>Сссылка к Youtube?</p>
            <input 
            v-model="Youtube" 
            placeholder="Youtube"
            class="form-control"
            type="text">
         </div>
        <button 
        type="submit"
        class="btn btn-primary w-100 mt-2 mb-5"
        :disabled="Install.length < 4">
            Добавить
        </button>
    </form>
    <button 
    @click="getPrizeUsers"
    class="btn btn-primary w-100">Получить данные</button>
</div>
<div class="content" v-if="Prize">
 <div 
    class="content-users mt-2" v-for="user in Prize" :key="user.id">
       <div>{{ user.Title }}</div>
       <div style="font-size:5px;">{{ user.TitleText }}</div>
       <button 
       @click="DeleteUser(user)"
       class="btn btn-primary mb-3">Удалить</button>
    </div>
</div>
<h4 v-else>Пока данные нет!</h4>
</template>
<style scoped>
input, textarea{
    width: 80%;
    margin-bottom: 5px;
    order: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.container-form{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-Infomore input{
  width: 100%;
}
.content{
    width: 100%;
    padding: 25px;
}
.content-users{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media(max-width: 420px){
    .content{
        padding: 0;
    }
    .container-form{
        display: block;
    }
    .content-users{
        display: block;
    }
}
</style>