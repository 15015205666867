<template>
      <div 
      class="box-card">
            <video preload="auto" loop autoplay muted>
              <source type="video/webm" src="../assets/videos/dotaback.mp4" class="rounded">
          </video>
          <router-link to="/prices">
            <div class="buyknopka2">
              <span class="icon_maphack2"></span>
              <span class="buyknopka_title2">Купить</span>
              <span class="buyknopka_portred2"></span>
            </div>
          </router-link>
        </div>
        <h3 class="pb-3">Последные Новости</h3>
        <h4 
          style="text-align: center; font-family: 'vue-w25'; margin-top: 25px;"
            v-if="loading">
                Загрузка...
        </h4>
          <div
          v-else 
          class="boxes-news" 
           :key="news.id"
           v-for="news in Resource">
            <router-link :to="Category + news.Router">
            <div class="news-container">
              <div class="left-cont">
                <img :src="news.Resimg">
              </div>
              <div class="right-cont">
                <h1>{{ news.Title }}</h1>
                <h3>{{ news.TitleText }}</h3>
              </div>
          </div>
          </router-link>
          </div>
</template>

<script setup>
import axios from 'axios';
import configs from '@/configs/config';
import { ref , onMounted } from 'vue';

const loading = ref(true)

const Category = ref('/resource/')

const Resource = ref('')

onMounted(() => {
    getDetailsRes()
 })

 async function getDetailsRes() {
    try {
    loading.value = true;
    const response = await axios.get(`${configs.BackJSON}/ResourceDota?_limit=3`)
    Resource.value = response.data
    } catch (err) {
    console.log(err)
    } finally {
        setTimeout(() => {
            loading.value = false;
        }, 500);
    }
}
</script>

<style>
.news-container{
  width: 100%;
  display: flex;
  margin-bottom: 15px;
}
.left-cont img{
  width: 100%;
  height: auto;
  border-radius: 15px;
}
.right-cont h1{
  font-size: x-large;
  font-family: 'vue-w25';
  margin-top: 5px;
}
.right-cont h3{
  font-size: small;
  font-family: 'vue-w25';
}
.right-cont{
  width: 48%;
}
.left-cont{
  width: 48%;
}
p{
  font-family: 'vue-w25';
  font-size: larger;
}
.boxes-news {
  width: 100%;
  border-radius: 15%;
}
video{
  height: 100%;
  object-fit: cover;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 15px;
}

.box-card {
  height: auto;
}
.p-90{
  padding-top: 90px;
}
h5{
  font-size: xx-large;
  font-family: 'vue-w25';
  color: black;
}

.buyknopka2{
    display: flex;
    align-items: center;
    height: 62px;
    width: 309px;
    font-size: 24px;
    color: #fff;
    box-shadow: 3px 3px 13px rgb(0 0 0 / 13%);
    border-radius: 5px;
    background-image: url('../assets/img/btn-start-bg2.png');
    background-repeat: no-repeat;
    margin: 10px auto;
}
.icon_maphack2{
    width: 33.3%;
    height: 50px;
    margin: auto;
    background-image: url('../assets/img/ico-start-btn2.png');
    background-repeat: no-repeat;
}
.buyknopka_portred2{
    background-image: url('../assets/img/btn-start-ico2.png');
    background-repeat: no-repeat;
    display: block;
    height: 62px;
    width: 33.3%;
}

@media only screen and (max-width: 420px) {
  .p-90{
    padding-top: 90px;
  }
  h3{
    font-family: 'vue-w22';
    font-size: 15px;
  }
  p{
    font-size: small;
  }
  .right-cont, .left-cont {
    width: 99%;
  }
  .news-container{
    flex-wrap: wrap;
  }

}
</style>