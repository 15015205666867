<template>
    <div class="roulette-container">
      <div class="roulette-strip">
        <div
          class="number"
          v-for="(item, index) in visibleNumbers"
          :key="item.id"
          :style="{
            backgroundColor: getColor(item.number),
            transform: `translateX(${index === 6 && isSpinning ? animatePosition : 0}px)`
          }"
        >
          <img :src="getImage(item.number)" alt="number image" class="number-image" @error="imageError(item.number)" />
          <div class="number-title">{{ item.title }}</div>
        </div>
      </div>
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <p>Поздравляем, вы выиграли {{ selectedTitle }}!</p>
          <button 
          class="btn btn-primary"
          @click="closeModal">Закрыть</button>
        </div>
      </div>
    </div>
    <div class="ruletInfo">
        <p>1 вращения - 4 
        <img src="../assets/img/keysAmount.png"
        width="30"
        class="rounded">!</p>
    <span 
    class="amount-txt">                          
    <img src="../assets/img/keysAmount.png"
        width="30"
        class="rounded">{{InfKeys}}
    </span>
    </div>
    <button 
    class="btn btn-secondary"
    @click="spin" :disabled="isSpinning || InfKeys < 4">Вращать</button>  
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import configs from '@/configs/config';
  import spineffect from '../assets/music/spineffect.mp3';
  import photo1 from '../assets/img/sunduk_item1.png';
  import photo2 from '../assets/img/sunduk_item7.png';
  import photo3 from '../assets/img/sunduk_item3.png';
  import photo4 from '../assets/img/sunduk_item4.png';
  import photo5 from '../assets/img/sunduk_item5.png';
  import photo6 from '../assets/img/sunduk_item6.png';
  import photo7 from '../assets/img/sunduk_item2.png';
  import photo8 from '../assets/img/sunduk_item8.png';
  
      const selectedNumber = ref(null);
      const isSpinning = ref(false);
      const showModal = ref(false);
      const animatePosition = ref(0);
      const selectedTitle = ref('');
      const InfKeys = ref(null)
  
      const userKeys = window.localStorage.getItem('identification')
      const userKeysA = userKeys
      const userJwt = window.localStorage.getItem('sc_359649')
      const userJwtG = userJwt
  
      const rouletteSound = new Audio(spineffect);
  
      const numbers = [
        { number: 1, title: 'Скидка 20%', id: 1 },
        { number: 2, title: 'Монета 6шт', id: 2 },
        { number: 3, title: '30 Дней подписки', id: 3 },
        { number: 4, title: 'Скидка 12%', id: 4 },
        { number: 5, title: '7 Дня подписки', id: 5 },
        { number: 6, title: 'Скидка 15%', id: 6 },
        { number: 7, title: '14 Дней подписки', id: 7 },
        { number: 8, title: 'Пусто', id: 8 },
      ];
  
      // Dastlab 7 (yarmi), 1, 2, 3, 4, 5, 6 (yarmi) ko‘rinadi
      const visibleNumbers = ref([
        numbers[6], // 7 (chap yarmi)
        numbers[0], // 1
        numbers[1], // 2
        numbers[2], // 3
        numbers[3], // 4
        numbers[4], // 5
        numbers[5], // 6 (o‘ng yarmi)
      ]);
      const getColor = (number) => {
        if (!isSpinning.value && selectedNumber.value === number) {
          return '#ff00000f';
        }
        return 'none';
      };
  
      const getImage = (number) => {
        const images = {
          1: photo1,
          2: photo8,
          3: photo3,
          4: photo4,
          5: photo5,
          6: photo6,
          7: photo2,
          8: photo7,
        };
        return images[number];
      };
  
      const imageError = (number) => {
        console.error(`Ошибка загрузки изображения для числа ${number}: ${getImage(number)}`);
      };
  
      const spin = async () => {
        if (isSpinning.value || InfKeys.value < 4) {
          return;
        }
        isSpinning.value = true;
        selectedNumber.value = null;
        selectedTitle.value = '';
        showModal.value = false;
  
        InfKeys.value--;
        InfKeys.value--;
        InfKeys.value--;
        InfKeys.value--;
  
        console.log('Путь к звуку:', rouletteSound.src);
        rouletteSound.currentTime = 0;
        try {
          await rouletteSound.play();
        } catch (error) {
          console.error('Ошибка воспроизведения звука:', error);
        }
  
        const targetNumber = Math.floor(Math.random() * 8) + 1;
        const blockWidth = 210; // 200px + 10px (5px chap + 5px o‘ng)
        const totalBlocks = 8;
        const cycles = 2; // Ikki to‘liq aylanish
        const steps = cycles * totalBlocks; // 16 qadam
        const duration = 3700; // 4 sekund
        const stepDuration = duration / steps; // Har bir qadam uchun vaqt (250ms)
        const currentStep = ref(0)
  
        const animate = async () => {
          const elapsed = Date.now() - startTime.value;
          if (elapsed < duration) {
            animatePosition.value += blockWidth / (stepDuration / 16); // O‘ngga siljish tezligi
            if (animatePosition.value >= blockWidth) {
              animatePosition.value = 0;
              visibleNumbers.value.shift(); // Birinchi blokni olib tashlaymiz
              const nextIndex = (visibleNumbers.value[visibleNumbers.value.length - 1].number % totalBlocks) + 1;
              visibleNumbers.value.push(numbers[nextIndex - 1]); // Yangi blokni qo‘shamiz
              currentStep.value++;
            }
            requestAnimationFrame(animate);
          } else {
            // G‘olibni markazga (3-pozitsiyaga) qo‘yish
            while (visibleNumbers.value[3].number !== targetNumber) {
              visibleNumbers.value.shift();
              const nextIndex = (visibleNumbers.value[visibleNumbers.value.length - 1].number % totalBlocks) + 1;
              visibleNumbers.value.push(numbers[nextIndex - 1]);
              await new Promise((resolve) => setTimeout(resolve, 50)); // Tezroq sinxronlash uchun
            }
            animatePosition.value = 0;
            selectedNumber.value = targetNumber;
            selectedTitle.value = numbers.find(num => num.number === targetNumber).title;
            sendPrize()
            updateSpins()
            isSpinning.value = false;
            showModal.value = true;
            rouletteSound.pause();
            rouletteSound.currentTime = 0;
            console.log('Звук остановлен');
          }
        };
  
        const startTime = ref(Date.now());
        requestAnimationFrame(animate);
      };
  
      const closeModal = () => {
        showModal.value = false;
      };
  
      const updateSpins = async () => {
      await axios({
          method: 'PUT',
              url: `${configs.apiURL}/api/users/${userKeysA}?fields=Amount`,
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userJwtG}`
              },
              data: {
                  'id': userKeysA,
                  'Amount': InfKeys.value
              }
          })
          .then(() => {
              console.log('Nothing')
          })
          .catch(() => {
              alert('Что то не так!')
          })
      }  
  
      const getAmount = async () => {
          await axios.get(`${configs.apiURL}/api/users/${userKeysA}`, {
              headers: {
                  'Content-type': 'application/json',
                  'Accept': 'application/json',
                  Authorization: `Bearer ${userJwtG}`
              }
           })
          .then(response => {
              InfKeys.value = response.data.Amount
          })
          .catch(err => {
                console.log(err)
           })        
      }
      const sendPrize = async () => {
      await axios.post(`${configs.BackURL}/sendedMessage`,{
      text: '<pre>Hackcup!\n</pre>' + `\n<b>Приз: ${selectedTitle.value}</b>` + `<pre> ID - ${userKeysA}</pre>`
      })
          .then(() =>{
              console.log('123')
          })
          .catch(() =>{
              console.log('Упс... Что то не так!')
          })
      }
  
      onMounted(() => {
        getAmount()
      })
  </script>
  
<style scoped>
.ruletInfo{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}
  /* Desktop stillari */
  .roulette-container {
    position: relative;
    width: 800px; /* 5 blok (1050px) + chapda 7 yarmi (105px) + o‘ngda 6 yarmi (105px) */
    height: 250px;
    margin: 20px auto;
    overflow: hidden;
  }
  
  .roulette-strip {
    display: flex;
    height: 100%;
    width: 1470px; /* 7 blok (5 to‘liq + 2 yarmi) * 210px */
    position: relative;
    left: -105px; /* Chapda 7 blokning yarmi ko‘rinadi */
  }
  
  .number {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    color: white;
    flex-shrink: 0;
    transition: transform 0.3s ease-in-out, border 0.2s ease;
    padding: 10px;
    border-radius: 8px;
    margin: 0 5px; /* Chap va o‘ngdan 5px bo‘sh joy */
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .number-text {
    text-shadow: 2px 2px 4px #710101;
  }
  
  .number-image {
    width: 150px;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .number:hover .number-image {
    transform: scale(1.05);
  }
  
  .number-title {
    font-size: 18px;
    text-shadow: 2px 2px 4px #710101;
    font-family: 'vue-w25';
    margin-bottom: 10px;
  }
  
  /* Telefon uchun stillar */
  @media (max-width: 768px) {
    .roulette-container {
      width: 99%; /* Telefon ekranining 99% kengligi */
      height: 500px; /* Balandlikni oshiramiz, chunki yarmi ko‘rinadi */
      overflow: hidden;
    }
  
    .roulette-strip {
      width: 100%;
      height: 100%;
      flex-direction: column; /* Vertikal joylashuv */
      position: relative;
      top: -125px; /* Tepadan yarmi ko‘rinadi */
      left: 0;
    }
  
    .number {
      width: 99%; /* Har bir blok ekran kengligining 99% */
      height: 250px; /* Balandlikni 250px qilib, yarmi ko‘rinadi */
      margin: 5px 0; /* Tepadan va pastdan 5px bo‘sh joy */
      padding: 10px;
      box-sizing: border-box;
    }
  
    .number-text {
      font-size: 24px; /* Telefon uchun kichikroq font */
    }
  
    .number-image {
      width: 80px;
      height: 80px;
    }
  
    .number-title {
      font-size: 16px;
    }
  }
  
  button {
    display: block;
    margin: 10px auto;
    padding: 10px 20px;
    font-size: 16px;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .spins-info {
    text-align: center;
    color: red;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content p {
    font-size: x-large;
    color: #ff0707;
    margin: 0 0 20px;
  }
  
  .modal-content button {
    padding: 10px 20px;
    font-size: 16px;
  }
</style>